var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"data-source-modal","header-bg-variant":"primary","hide-footer":"","body-class":`${_vm.Theme === 'dark' && 'dark'} dialog2`,"dialog-class":"dialog","centered":"","title":"Data source","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"text-white"},[_vm._v("Data source")])]},proxy:true}])},[_c('b-card',{staticClass:"p-3",staticStyle:{"max-height":"400px","overflow-y":"auto"}},_vm._l((_vm.dataCopy),function(table,i){return _c('div',{key:i},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","header-bg-variant":_vm.data.find((o) => o.table_name === table.table_name)
                            ? 'primary'
                            : 'disabled',"role":"tab"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"97%","text-align":"center"}},[(
                                    _vm.data.find(
                                        (o) =>
                                            o.table_name ===
                                            table.table_name
                                    )
                                )?_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`${i}`),expression:"`${i}`"}],staticClass:"text-white"},[_vm._v(_vm._s(table.table_name))]):_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(table.table_name))])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"3%"}},[(
                                    _vm.data.find(
                                        (o) =>
                                            o.table_name ===
                                            table.table_name
                                    ) === undefined
                                )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('No seleccionada'),expression:"'No seleccionada'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 text-secondary",attrs:{"type":"square","role":"button"},on:{"click":function($event){return _vm.addRemoveTable(1, _vm.dataCopy[i])}}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Sede seleccionada'),expression:"'Sede seleccionada'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 text-blue-active",attrs:{"type":"check-square","role":"button"},on:{"click":function($event){return _vm.addRemoveTable(-1, _vm.dataCopy[i], i)}}})],1)])]),_c('b-collapse',{attrs:{"id":`${i}`,"accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-list-group',_vm._l((table.campos),function(field,iF){return _c('b-list-group-item',{key:`f-${iF}`,staticClass:"d-flex justify-content-between align-items-center",on:{"click":function($event){return _vm.addRemoveItem(table.table_name, field)}}},[_vm._v(" "+_vm._s(field)+" "),(
                                        _vm.data
                                            .find(
                                                (t) =>
                                                    t.table_name ===
                                                    table.table_name
                                            )
                                            ?.campos.indexOf(field) === -1
                                    )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                        'No seleccionada'
                                    ),expression:"\n                                        'No seleccionada'\n                                    ",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 text-secondary",attrs:{"type":"square","role":"button"}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                        'Sede seleccionada'
                                    ),expression:"\n                                        'Sede seleccionada'\n                                    ",modifiers:{"hover":true,"top":true}}],staticClass:"mr-2 text-blue-active",attrs:{"type":"check-square","role":"button"}})],1)}),1)],1)],1)],1)],1)}),0),(_vm.$v.data.$error && !_vm.$v.data.required)?_c('div',{staticClass:"invalid-field mt-1 p-3"},[_vm._v(" Debe agregar mínimo una tabla para editar el reporte ")]):_vm._e(),(
            _vm.$v.data.$error &&
            _vm.data.filter((f) => f.campos.length === 0).length > 0
        )?_c('div',{staticClass:"invalid-field mt-1 p-3"},[_vm._v(" Cada tabla debe tener mínimo un campo agregado ")]):_vm._e(),_c('div',{staticClass:"mt-5 float-right"},[_c('b-button',{staticClass:"mt-4",attrs:{"variant":"primary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.sendTables}},[(_vm.isLoading)?_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Cargando...")])],1):_c('span',[_vm._v("Aceptar")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
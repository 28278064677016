<template>
    <b-modal
        id="modal-tipo-reporte"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        no-close-on-esc
        no-close-on-backdrop
        centered
    >
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5 class="text-white">Seleccione tel tipo de reporte</h5>
        </template>
        <b-card class="p-3">
            <b-row>
                <b-col
                    cols="12"
                    :sm="form.tipoReporte === 'DOCUMENTOS' ? 6 : 12"
                >
                    <b-form-group>
                        <label for="tipoReporte">Tipo de reporte</label>
                        <jautocomplete
                            id="tipoReporte"
                            :items="tiposReportes"
                            item-value="value"
                            item-text="label"
                            open-on-focus
                            v-model="$v.form.tipoReporte.$model"
                            :state="validateState('tipoReporte')"
                            :disabled="isLoading"
                        >
                        </jautocomplete>
                        <div
                            class="invalid-field mt-1"
                            v-if="
                                !$v.form.tipoReporte.required &&
                                $v.form.tipoReporte.$error
                            "
                        >
                            Campo requerido
                        </div>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    v-if="form.tipoReporte === 'DOCUMENTOS'"
                >
                    <b-form-group>
                        <label for="documento">Formato</label>
                        <jautocomplete
                            id="documento"
                            :items="documentos"
                            item-value="value"
                            item-text="label"
                            open-on-focus
                            v-model="$v.form.documento.$model"
                            :state="validateState('documento')"
                            :disabled="isLoading"
                        >
                        </jautocomplete>
                        <div
                            class="invalid-field mt-1"
                            v-if="
                                !$v.form.documento.required &&
                                $v.form.documento.$error
                            "
                        >
                            Campo requerido
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <div class="float-right">
            <b-button
                variant="primary"
                :disabled="isLoading"
                size="md"
                @click="setTipeReport"
            >
                <span
                    v-if="isLoading"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-spinner small></b-spinner>
                    <span class="ml-2">Generando...</span>
                </span>
                <span v-else>Aceptar</span>
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { requiredIf, required } from 'vuelidate/lib/validators'
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { mapState, mapGetters } from 'vuex'

const defaultForm = () =>
    JSON.parse(
        JSON.stringify({
            tipoReporte: null,
            documento: null,
            empresaId: null
        })
    )

const { REPORTER } = services

export default {
    components: { jautocomplete },
    mixins: [validationMixin],
    name: 'TipoReporte',
    data: () => ({
        isLoading: false,
        tiposReportes: [
            { label: 'Informe', value: 'MAESTROS' },
            { label: 'Formatos de impresión', value: 'DOCUMENTOS' }
        ],
        documentos: [
            { label: 'Factura de venta', value: 'FACTURA_VENTA' },
            { label: 'Devolución en venta', value: 'DEVOLUCION_VENTA' },
            { label: 'Devolución en venta', value: 'DEVOLUCION_VENTA' },
            { label: 'Cotizaciones de cliente', value: 'COTIZACIONES_CLIENTE' },
            { label: 'Pedidos de cliente', value: 'PEDIDOS_CLIENTE' },
            { label: 'Remisiones de cliente', value: 'REMISIONES_CLIENTE' },
            { label: 'Compras de mercancías', value: 'COMPRAS_MERCANCIAS' },
            {
                label: 'Cotizaciones de proveedor',
                value: 'COTIZACIONES_PROVEEDOR'
            },
            { label: 'Devolución proveedor', value: 'DEVOLUCION_PROVEEDOR' },
            { label: 'Pedidos proveedor', value: 'PEDIDOS_PROVEEDOR' },
            { label: 'Remisiones proveedor', value: 'REMISIONES_PROVEEDOR' }
        ],
        form: defaultForm()
    }),
    validations: {
        form: {
            tipoReporte: {
                required
            },
            documento: {
                required: requiredIf(({ tipoReporte }) => {
                    return tipoReporte === 'DOCUMENTOS'
                })
            }
        }
    },
    methods: {
        showLog(data) {
            console.log(data)
        },
        setTipeReport() {
            const valid = !this.$v.form.$invalid
            if (valid) {
                this.isLoading = true
                this.form.empresaId = this.getUltimaEmpresa.id

                REPORTER.POST({
                    url: '/reporter/data-document',
                    data: {
                        ...this.form
                    }
                })
                    .then((response) => {
                        const { data } = response
                        this.isLoading = false
                        // console.log(data)
                        if (this.form.tipoReporte === 'DOCUMENTOS') {
                            this.$emit('datasetDocuemntos', data.datasource)
                            this.$emit('selectedTables', {
                                query: data.query,
                                is_document: true
                            })
                        } else {
                            this.$emit('tablesMaestros', data)
                        }
                        this.form = defaultForm()
                        this.$v.form.$reset()
                        this.close()
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
                // setTimeout(() => {
                //     console.log(this.form)
                //     this.form = defaultForm()
                //     this.$v.form.$reset()
                //     this.close()
                //     this.isLoading = false
                // }, 2000)
            } else {
                this.$v.form.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        },
        close() {
            this.$bvModal.hide('modal-tipo-reporte')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 50 }
            return json
        },
        ...mapState(['Theme']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>

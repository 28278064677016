<template>
    <b-modal
        id="data-source-modal"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Data source"
        no-close-on-esc
        no-close-on-backdrop
    >
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5 class="text-white">Data source</h5>
        </template>
        <!-- @hidden="items = []" -->
        <!-- @show="" -->
        <!-- getLotesInventarios(),
                getBodegas(),
                getSaldosInventario(1, pagination.perPage) -->
        <b-card class="p-3" style="max-height: 400px; overflow-y: auto">
            <div v-for="(table, i) in dataCopy" :key="i">
                <b-card no-body class="mb-1">
                    <b-card-header
                        header-tag="header"
                        :header-bg-variant="
                            data.find((o) => o.table_name === table.table_name)
                                ? 'primary'
                                : 'disabled'
                        "
                        class="p-1"
                        role="tab"
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                            "
                        >
                            <div
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    width: 97%;
                                    text-align: center;
                                "
                            >
                                <span
                                    v-if="
                                        data.find(
                                            (o) =>
                                                o.table_name ===
                                                table.table_name
                                        )
                                    "
                                    v-b-toggle="`${i}`"
                                    class="text-white"
                                    >{{ table.table_name }}</span
                                >
                                <span v-else class="text-black">{{
                                    table.table_name
                                }}</span>
                            </div>
                            <div
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    width: 3%;
                                "
                            >
                                <feather
                                    v-b-popover.hover.top="'No seleccionada'"
                                    v-if="
                                        data.find(
                                            (o) =>
                                                o.table_name ===
                                                table.table_name
                                        ) === undefined
                                    "
                                    type="square"
                                    role="button"
                                    class="mr-2 text-secondary"
                                    @click="addRemoveTable(1, dataCopy[i])"
                                ></feather>
                                <feather
                                    v-else
                                    @click="addRemoveTable(-1, dataCopy[i], i)"
                                    v-b-popover.hover.top="'Sede seleccionada'"
                                    type="check-square"
                                    role="button"
                                    class="mr-2 text-blue-active"
                                ></feather>
                            </div>
                        </div>
                    </b-card-header>
                    <b-collapse
                        :id="`${i}`"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <!-- <b-card-text
                                v-for="(field, iF) in table.campos"
                                :key="`f-${iF}`"
                            >
                                {{ field }}
                            </b-card-text> -->
                            <b-list-group>
                                <b-list-group-item
                                    v-for="(field, iF) in table.campos"
                                    :key="`f-${iF}`"
                                    @click="
                                        addRemoveItem(table.table_name, field)
                                    "
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    {{ field }}
                                    <feather
                                        v-b-popover.hover.top="
                                            'No seleccionada'
                                        "
                                        v-if="
                                            data
                                                .find(
                                                    (t) =>
                                                        t.table_name ===
                                                        table.table_name
                                                )
                                                ?.campos.indexOf(field) === -1
                                        "
                                        type="square"
                                        role="button"
                                        class="mr-2 text-secondary"
                                    ></feather>
                                    <feather
                                        v-else
                                        v-b-popover.hover.top="
                                            'Sede seleccionada'
                                        "
                                        type="check-square"
                                        role="button"
                                        class="mr-2 text-blue-active"
                                    ></feather>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-card>
        <div
            v-if="$v.data.$error && !$v.data.required"
            class="invalid-field mt-1 p-3"
        >
            Debe agregar mínimo una tabla para editar el reporte
        </div>
        <div
            v-if="
                $v.data.$error &&
                data.filter((f) => f.campos.length === 0).length > 0
            "
            class="invalid-field mt-1 p-3"
        >
            Cada tabla debe tener mínimo un campo agregado
        </div>
        <div class="mt-5 float-right">
            <b-button
                variant="primary"
                :disabled="isLoading"
                size="md"
                class="mt-4"
                @click="sendTables"
            >
                <span
                    v-if="isLoading"
                    class="d-flex align-items-center justify-content-center"
                >
                    <b-spinner small></b-spinner>
                    <span class="ml-2">Cargando...</span>
                </span>
                <span v-else>Aceptar</span>
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import services from '@/boot/axios'
// import { currencyFormat } from '@/utils/others'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// import Jautocomplete from '@/components/shared/autocomplete/jautocomplete.vue'
// import yearPickerVue from '@/components/shared/year-picker/year-picker.vue'

const { REPORTER } = services

export default {
    name: 'DataSourceModal',
    props: {
        tables: { type: Array, default: () => [] }
    },
    // components: { Jautocomplete, yearPickerVue },
    mixins: [validationMixin],
    mounted() {},
    watch: {
        tables: {
            handler(newValue) {
                // this.data = JSON.parse(JSON.stringify(newValue)).map((t) => ({
                //     ...t,
                //     campos: []
                // }))
                this.dataCopy = JSON.parse(JSON.stringify(newValue))
            },
            deep: true
        }
    },
    data: () => ({
        dataCopy: [],
        data: [],
        isLoading: false
    }),
    validations: {
        data: {
            required,
            minLength: minLength(1),
            $each: {
                campos: {
                    required,
                    minLength: minLength(1)
                }
            }
        }
    },
    methods: {
        showLog(data) {
            console.log(data)
        },
        getTables() {
            REPORTER.GET({
                url: '/reporter/data'
            })
                .then((response) => {
                    const { data } = response
                    this.dataCopy = JSON.parse(JSON.stringify(data))
                    this.data = JSON.parse(JSON.stringify(data))
                    // if (status === 'ok') {
                    // } else {
                    //     this.$bvModal
                    //         .msgBoxOk(
                    //             mensaje ||
                    //                 mensaje_exception ||
                    //                 'Ha ocurrido un error!',
                    //             {
                    //                 title: 'Error!',
                    //                 size: 'sm',
                    //                 buttonSize: 'sm',
                    //                 okVariant: 'error',
                    //                 okTitle: 'Aceptar',
                    //                 cancelTitle: 'Cancelar',
                    //                 footerClass: 'p-2',
                    //                 hideHeaderClose: false,
                    //                 centered: true,
                    //                 headerBgVariant: 'error',
                    //                 headerClass: 'modal-header'
                    //             }
                    //         )
                    //         .then(() => {
                    //             // console.log(value);
                    //         })
                    //         .catch((err) => {
                    //             console.log(err)
                    //         })
                    // }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        sendTables() {
            this.isLoading = true

            const valid = !this.$v.data.$invalid

            if (valid) {
                REPORTER.POST({
                    url: '/reporter/data',
                    data: {
                        query: this.data,
                        empresaId: this.getUltimaEmpresa.id
                    }
                })
                    .then((response) => {
                        const { data } = response
                        this.isLoading = false
                        // console.log(data)
                        this.$emit('changeDataSource', data)
                        this.$emit('selectedTables', {
                            query: this.data,
                            is_document: false
                        })
                        this.close()
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.isLoading = false
                this.$v.data.$touch()
            }
        },
        addRemoveTable(value, reference, i) {
            if (value === 1) {
                console.log(value, reference, i)
                this.data.push({ ...reference, campos: [] })
            } else {
                const obj = this.data.find(
                    (o) => o.table_name === reference.table_name
                )
                const index = this.data.indexOf(obj)
                this.data.splice(index, 1)

                this.collapseTab(i)
            }
        },
        collapseTab(i) {
            const isCollapse = document
                .getElementById(i)
                .classList.contains('show')

            if (isCollapse) {
                this.$root.$emit('bv::toggle::collapse', `${i}`)
            }
        },
        addRemoveItem(tableName, field) {
            // console.log(i, field)
            // console.log(this.data[i])
            const table = this.data.find((t) => t.table_name === tableName)
            const indexTable = this.data.indexOf(table)
            const index = this.data[indexTable].campos.indexOf(field)
            if (index === -1) {
                this.data[indexTable].campos.push(field)
            } else {
                this.data[indexTable].campos.splice(index, 1)
            }
        },
        close() {
            this.$bvModal.hide('data-source-modal')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 15 }
            return json
        },
        ...mapState(['Theme']),
        ...mapGetters('usuario', ['getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>

<template>
    <div>
        <div id="designer"></div>
        <modalTemplatesVue @selectedTemplate="setTemplate" />
        <dataSourceModal
            @changeDataSource="changeDataSource"
            @selectedTables="selectedTables"
            :tables="tables"
        />
        <tipoReporte
            @datasetDocuemntos="changeDataSource"
            @selectedTables="selectedTables"
            @tablesMaestros="
                (v) => {
                    tables = v
                    requestSourceData()
                }
            "
        />
    </div>
</template>
<script>
import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.designer.js'
import services from '@/boot/axios'
import { mapGetters } from 'vuex'
import modalTemplatesVue from '@/components/shared/modal-templates/modal-templates.vue'
import dataSourceModal from '@/components/shared/data-source/data-source-modal.vue'
import tipoReporte from '@/components/shared/modal-tipo-reporte/tipo-reporte.vue'

const { API, REPORTER } = services

export default {
    name: 'Reportes',
    data: () => ({
        templates: [],
        designer: null,
        currentTemplate: null,
        selectedTablesFields: [],
        tables: []
    }),
    components: { modalTemplatesVue, dataSourceModal, tipoReporte },
    mounted() {
        const self = this

        Stimulsoft.Base.StiLicense.key = process.env.VUE_APP_STIMULSOFT_LICENSE

        var options = new Stimulsoft.Designer.StiDesignerOptions()
        options.appearance.fullScreenMode = false

        this.designer = new Stimulsoft.Designer.StiDesigner(
            options,
            'StiDesigner',
            false
        )

        this.designer.onSaveReport = function (e) {
            var jsonStr = e.report.saveToJsonString()
            const jsonObj = JSON.parse(jsonStr)
            const filters = self.getFilters(jsonObj)

            self.saveTemplate({ filename: e.fileName, data: jsonStr, filters })
        }

        var report = new Stimulsoft.Report.StiReport()

        this.designer.onOpenReport = () => {
            self.openTemplateModal()
        }

        this.designer.report = report

        this.designer.renderHtml('designer')

        const { template_id } = self.$route.params
        if (template_id) {
            this.getTemplate(template_id)
        } else {
            this.requestTipoReporte()
            // this.requestSourceData()
        }
    },
    methods: {
        requestTipoReporte() {
            this.$bvModal.show('modal-tipo-reporte')
        },
        requestSourceData() {
            this.$bvModal.show('data-source-modal')
        },
        getFilters(jsonObj) {
            let filters = {}
            Object.entries(jsonObj.Pages).forEach(([index, page]) => {
                index
                Object.entries(page.Components).forEach(([i, component]) => {
                    i
                    const filtersComponent = component.Filters
                        ? Object.entries(component.Filters).map(
                              ([iF, filter]) => {
                                  iF
                                  return filter
                              }
                          )
                        : null

                    if (filtersComponent) {
                        filters[component.DataSourceName] = filtersComponent
                    }
                })
            })

            return filters
        },
        openTemplateModal() {
            this.$bvModal.show('modal-template')
        },
        setTemplate(template) {
            // console.log(template)
            this.currentTemplate = JSON.parse(JSON.stringify(template))
            this.$bvModal.hide('modal-template')
            this.designer.report.load(template.data)
            this.designer.report.reportName = template.nombre.replace(
                '.mrt',
                ''
            )
            this.designer.renderHtml('designer')

            const dataSet = new Stimulsoft.System.Data.DataSet('default')
            dataSet.readJson(template.dataSource)
            this.designer.report.dictionary.databases.clear()
            this.designer.report.regData('default', 'default', dataSet)
            this.designer.report.dictionary.synchronize()
        },
        getTemplate(id) {
            REPORTER.GET({
                url: `/template/${id}`
            })
                .then((response) => {
                    const { data } = response

                    this.setTemplate(data)
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        saveTemplate({ filename, data, filters }) {
            const option = this.currentTemplate ? 'UPDATE' : 'INSERT'
            const template_id = this.currentTemplate
                ? this.currentTemplate.id
                : undefined
            const { query, is_document } = this.selectedTablesFields

            API.POST({
                url: 'report/template/crud',
                data: {
                    p_datajson: {
                        nombre: filename,
                        is_document,
                        data,
                        query: {
                            query,
                            filters
                        },
                        id: template_id,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: option,
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.currentTemplate = dato
                        this.$notify({
                            group: 'general',
                            title: 'GExito!',
                            text: 'La platilla se ha guardado correctamente',
                            ignoreDuplicates: true,
                            duration: 5000,
                            type: 'success'
                        })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        selectedTables(tables) {
            this.selectedTablesFields = tables
        },
        changeDataSource(dataSource) {
            // console.log(dataSource)
            const report = new Stimulsoft.Report.StiReport()
            const dataSet = new Stimulsoft.System.Data.DataSet('default')
            dataSet.readJson(dataSource)
            report.dictionary.databases.clear()
            report.regData('default', 'default', dataSet)
            report.dictionary.synchronize()
            this.designer.report = report
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 50 }
            return json
        },
        ...mapGetters('usuario', ['getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

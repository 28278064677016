<template>
    <b-modal
        id="modal-template"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
        title="Mis plantillas"
        @show="getTemplates('', 1, pagination.perPage)"
        @hidden="templates = []"
    >
        <b-card class="p-3">
            <b-input-group>
                <b-form-input
                    id="filter-input"
                    type="text"
                    placeholder="Buscar registros"
                    class="bg-background-input"
                    v-model="search"
                    @keydown.enter="searchData"
                    @input="search === '' && searchData()"
                ></b-form-input>

                <b-input-group-append>
                    <b-button
                        variant="background-input"
                        class="text-blue-active d-flex align-items-center"
                        @click="searchData()"
                    >
                        <feather type="search" size="1.1rem"></feather>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <div class="table-responsive mt-5">
                <b-table
                    :fields="fields"
                    :items="templates"
                    :tbody-tr-class="rowClass"
                    borderless
                    responsive
                    head-variant="light"
                    tbody-class="cursor-pointer"
                    class="mb-0 hover-table text-nowrap"
                    @row-clicked="setItem"
                >
                    <template #cell(nombre)="data">
                        {{ data.value }}
                    </template>
                    <!-- <template #cell(activo)="data">
                        <span
                            :class="`badge ${
                                data.value
                                    ? 'bg-light-success'
                                    : 'bg-light-secondary'
                            } ${
                                (Theme === 'dark' && 'text-white') ||
                                (Theme !== 'dark' && 'text-gray')
                            } badge-font-1`"
                        >
                            {{ data.value ? 'Activo' : 'Inactivo' }}
                        </span>
                    </template> -->
                </b-table>
            </div>
            <div class="p-3 d-flex align-items-center">
                <b-form-group
                    label="Número de registros"
                    label-for="per-page-select"
                    label-size="sm"
                    label-class="fw-medium"
                    class="mb-0"
                >
                    <b-form-select
                        id="per-page-select"
                        v-model="pagination.perPage"
                        :options="pagination.pageOptions"
                        @change="getTemplates('', 1, $event)"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
                <div class="ml-auto">
                    <b-pagination
                        first-number
                        last-number
                        size="sm"
                        v-model="pagination.currentPage"
                        :total-rows="pagination.totalRows"
                        :per-page="pagination.perPage"
                        @input="getTemplates('', $event, pagination.perPage)"
                        align="fill"
                        class="my-0"
                    ></b-pagination>
                </div>
            </div>
        </b-card>
    </b-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'ModalTemplates',
    data: () => ({
        templates: [],
        search: '',
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        fields: [
            // { key: "codigo", label: "Código" },
            { key: 'nombre', label: 'Nombre' }
            // { key: 'descripcion', label: 'Descripción' },
            // { key: 'codigo_barra', label: 'Código de barras' },
            // { key: 'activo', label: 'Activo' }
            // { key: 'acciones', label: 'Acciones' }
        ]
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        setItem(item) {
            this.$emit('selectedTemplate', item)
            this.close()
        },
        searchData() {
            this.getTemplates(this.search)
        },
        getTemplates(term = '', page = 1, limit = this.pagination.perPage) {
            API.POST({
                url: 'report/template/query',
                data: {
                    p_datajson: {
                        page,
                        term,
                        limit,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.templates = [...content]
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        close() {
            this.$bvModal.hide('modal-items')
        }
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 50 }
            return json
        },
        ...mapState(['Theme']),
        ...mapGetters('usuario', ['getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-tipo-reporte","header-bg-variant":"primary","hide-footer":"","body-class":`${_vm.Theme === 'dark' && 'dark'} dialog2`,"dialog-class":"dialog","no-close-on-esc":"","no-close-on-backdrop":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"text-white"},[_vm._v("Seleccione tel tipo de reporte")])]},proxy:true}])},[_c('b-card',{staticClass:"p-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":_vm.form.tipoReporte === 'DOCUMENTOS' ? 6 : 12}},[_c('b-form-group',[_c('label',{attrs:{"for":"tipoReporte"}},[_vm._v("Tipo de reporte")]),_c('jautocomplete',{attrs:{"id":"tipoReporte","items":_vm.tiposReportes,"item-value":"value","item-text":"label","open-on-focus":"","state":_vm.validateState('tipoReporte'),"disabled":_vm.isLoading},model:{value:(_vm.$v.form.tipoReporte.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.tipoReporte, "$model", $$v)},expression:"$v.form.tipoReporte.$model"}}),(
                            !_vm.$v.form.tipoReporte.required &&
                            _vm.$v.form.tipoReporte.$error
                        )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),(_vm.form.tipoReporte === 'DOCUMENTOS')?_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"documento"}},[_vm._v("Formato")]),_c('jautocomplete',{attrs:{"id":"documento","items":_vm.documentos,"item-value":"value","item-text":"label","open-on-focus":"","state":_vm.validateState('documento'),"disabled":_vm.isLoading},model:{value:(_vm.$v.form.documento.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.documento, "$model", $$v)},expression:"$v.form.documento.$model"}}),(
                            !_vm.$v.form.documento.required &&
                            _vm.$v.form.documento.$error
                        )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.setTipeReport}},[(_vm.isLoading)?_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Generando...")])],1):_c('span',[_vm._v("Aceptar")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }